import React, { useState } from 'react';
import SvgSuccess from '@ntuctech/devex-tangram/Icon/SvgSuccess';
import Toast from '@ntuctech/devex-tangram/Toast';
import Typography from '@ntuctech/devex-tangram/Typography';
import _get from 'lodash/get';
import { useRouter } from 'next/router';

import { FLAGS } from '../../services/feature-flag';
import { redirectToMyinfoVerify } from '../../services/myinfo-verification';
import { useUser } from '../../services/profile';
import { useFeatures } from '../DynamicFeatureProvider';
import { usePageContext } from '../PageContextProvider';
import SimpleModal from '../SimpleModal';

const ErrorModal = (props) => {
  const { open: openProp, title, body, ctaText, ctaAction } = props;

  return (
    <SimpleModal
      open={openProp}
      title={title}
      body={body}
      ctaText={ctaText}
      ctaAction={ctaAction}
      testId="ProfileError"
    />
  );
};

const SuccessToast = (props) => {
  const { open, message } = props;
  return open ? (
    <Toast inline icon={<SvgSuccess />} message={message} data-testid="InfoToast" />
  ) : null;
};

const HocProfileManager = (Component) => {
  const WrappedComp = (compProps) => {
    // routing
    const router = useRouter();
    const { getFlagConfig } = useFeatures();
    const myinfoSplitConfig = getFlagConfig(FLAGS.MYINFO_CLIENT_ID);
    const myinfoClientId = JSON.parse(myinfoSplitConfig)?.client_id;

    // success/error alerts
    const [modalData, setModalData] = useState({ open: false });
    const [toastData, setToastData] = useState({ open: false });

    // editing state & unsaved changes checking
    const { pageContext, setPageContext, isEditing, setIsEditing, checkUnsavedBeforeUpdate } =
      usePageContext();

    React.useEffect(() => {
      const beforeUnload = (e) => {
        if (isEditing) {
          e.preventDefault();
          e.returnValue = true;
        }
      };
      if (isEditing) {
        window.addEventListener('beforeunload', beforeUnload);
      } else {
        window.removeEventListener('beforeunload', beforeUnload);
      }
      return () => {
        window.removeEventListener('beforeunload', beforeUnload);
      };
    }, [isEditing]);

    // fetch user data
    const {
      user: localUser,
      setUser: setLocalUser,
      profileCompletionStatus,
      userIsReady,
    } = useUser();

    // open form
    const uid = _get(localUser, 'uid');
    const handleOpenForm = React.useCallback(
      (formName) => (e) => {
        e.preventDefault();

        let action = () => setPageContext(formName);
        switch (formName) {
          case 'name':
          case 'dob':
          case 'password':
            action = () => setPageContext(formName);
            break;
          case 'verify-email':
          case 'verify-mobile':
            action = () => router.push(`/${formName}`);
            break;
          case 'myinfo':
            action = () => redirectToMyinfoVerify({ uid, myinfoClientId });
            break;
          default:
            break;
        }
        checkUnsavedBeforeUpdate(action);
      },
      [checkUnsavedBeforeUpdate, setPageContext, router, uid, myinfoClientId],
    );

    // finish/cancel profile updating actions
    const handleFinishEditing = React.useCallback(() => {
      setPageContext('');
      setIsEditing(false);
    }, [setPageContext, setIsEditing]);

    const returnHome = () => {
      router.push('/');
    };

    // success/error handling
    const closeErrorModal = () => {
      setModalData({
        open: false,
      });
    };

    const openErrorModal = React.useCallback((error) => {
      const errorMessage = error.message;
      const title = errorMessage.includes('Password') ? 'Try another password' : 'Oops!';
      let bodyMessage;

      if (errorMessage) {
        if (errorMessage.includes('PasswordNoUserInfoError')) {
          bodyMessage = 'Your password should not contain your name, mobile number, or email.';
        } else {
          bodyMessage = errorMessage;
        }
      } else {
        bodyMessage = 'Something went wrong.';
      }

      setModalData({
        error: true,
        open: true,
        title: title,
        body: bodyMessage,
        ctaText: 'Okay, got it',
        ctaAction: closeErrorModal,
      });
    }, []);

    const showSuccessToast = (message) => {
      setToastData({ open: false });
      setToastData({ open: true, message: message });
    };

    const renderAlerts = React.useCallback(() => {
      return (
        <>
          <ErrorModal {...modalData} />
          <SuccessToast {...toastData} />
        </>
      );
    }, [modalData, toastData]);

    // basic info
    const name = _get(localUser, 'name');

    // email
    const email = _get(localUser, 'email');
    const emailVerified = _get(profileCompletionStatus, 'email_verification');

    // mobile number
    const countryCode = _get(localUser, 'country_code', '');
    const phoneNumber = _get(localUser, 'phone_number', '');
    const displayPhone = `+${countryCode}${phoneNumber}`;
    const isPhoneEmpty = phoneNumber === '00000000' || phoneNumber === '';
    const phoneVerified = _get(profileCompletionStatus, 'mobile_verification');

    // residential address
    const residentialAddress = _get(localUser, 'residential_address');

    // age verification (Myinfo)
    const myinfoVerified = _get(profileCompletionStatus, 'myinfo_verification');

    // date of birth
    const dateOfBirth = _get(localUser, 'date_of_birth');

    // Myinfo last verification date
    const myinfoLastVerifiedDate = _get(localUser, 'myinfo_details.myinfo_last_verified', '');

    // Myinfo verified attributes
    const myinfoVerifiedAttributes = _get(
      localUser,
      'myinfo_details.myinfo_verified_attributes',
      {},
    );

    // Myinfo existing data
    const myinfoExistingData = _get(localUser, 'myinfo_details.existing_myinfo_data', {});

    // password
    const editPasswordCallback = React.useCallback(
      (error) => {
        if (error) {
          openErrorModal(error);
        } else {
          // sign out immediately
          router.push('/api/auth/logout');
        }
      },
      [router, openErrorModal],
    );

    // loading
    if (!userIsReady) {
      return <Typography>Loading...</Typography>;
    }

    return (
      <>
        {renderAlerts()}
        <Component
          // profile data
          profile={{
            uid,
            name,
            email,
            emailVerified,
            phoneNumber,
            displayPhone,
            isPhoneEmpty,
            phoneVerified,
            residentialAddress,
            myinfoVerified,
            dateOfBirth,
            myinfoLastVerifiedDate,
            myinfoVerifiedAttributes,
            myinfoExistingData,
          }}
          setLocalUser={setLocalUser}
          // editing state & unsaved changes checking
          pageContext={pageContext}
          setIsEditing={setIsEditing}
          handleFinishEditing={handleFinishEditing}
          // actions
          handleOpenForm={handleOpenForm}
          returnHome={returnHome}
          // alerts
          showSuccessToast={showSuccessToast}
          openErrorModal={openErrorModal}
          // password
          editPasswordCallback={editPasswordCallback}
          {...compProps}
        />
      </>
    );
  };

  return WrappedComp;
};

export default HocProfileManager;
